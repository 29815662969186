import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {

  connect() {
    const toastFlash = document.getElementById('liveToast')
    const toast = new Toast(toastFlash)
    toast.show()
  }

};