import { Application } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
import { Autocomplete } from 'stimulus-autocomplete'
import * as ActiveStorage from "@rails/activestorage"

const application = Application.start()
application.register('autocomplete', Autocomplete)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }