// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

console.log('Hello World from application.js');

function toggleElement(element) {
    document.getElementById(element).classList.toggle('d-none');
}

window.toggleElement = toggleElement;